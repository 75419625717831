import React, { useEffect, useState } from 'react';
import { Container, Navbar, NavDropdown } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { selectUser } from '../../../../app/auth/authSlice';
import i18n from '../../../../config/i18n';
import {
    selectDropdownSelectableLangs,
    setDropdownSelectableLangs,
} from '../../../../features/auth/menu/menuLevels/languagesManagement/languagesManagementSlice';
import { getLanguages } from '../../../../features/auth/menu/menuLevels/languagesManagement/services/languages.service';
import { editUserPreferenceOnline } from '../../../../features/auth/menu/menuLevels/userManagement/services/userpreference.service';
import { menu } from '../../../../routes/routeNameAuthType';
import { useRoute } from '../../../../utils/routes/useRoute';
import Icon from '../../../shared/icon/Icon';
import LanguageSelector from '../../../shared/languageSelector/LanguageSelector';
import modalEvents from '../../../shared/redux/modal/modalEvent';
import { setModal } from '../../../shared/redux/modal/modalSlice';
import ModalEditProfile from './ModalEditProfile';
import useLogo from '../../../../hooks/useLogo';

const HeaderLayout = ({ logout }: { logout: () => void }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const { changeRoute } = useRoute();
    const user = useSelector(selectUser);
    const dropdownSelectableLangs = useSelector(selectDropdownSelectableLangs);
    const [selectedLanguage, setSelectedLanguage] = useState(i18n.language);
    const routePath = user?.firstPageAccess;

    const changeLanguage = (lng: string) => {
        setSelectedLanguage(lng);
        i18n.changeLanguage(lng);
        editUserPreferenceOnline({ language: lng }).then(res => window.location.reload());
    };
    const logoLink = useLogo();

    useEffect(() => {
        getLanguages().then(res => {
            let selectLang: any = res.map((ele: any) => ({
                label: ele.lang,
                langCode: ele.id,
                value: ele.id,
            }));

            dispatch(setDropdownSelectableLangs(selectLang));
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [i18n.store?.data]);

    const editProfile = () => {
        dispatch(
            setModal(
                [],
                {
                    title: t('USERMANAGEMENT.PROFILE'),
                    templateBody: 'editProfileUsr',
                },
                {},
                'lg'
            )
        );
    };
    modalEvents.templates.editProfileUsr = (
        <>
            <ModalEditProfile />
        </>
    );

    return (
        <header>
            <Navbar fixed="top" collapseOnSelect expand="lg" variant="dark">
                <Container fluid>
                    <div className="navbar-left"></div>
                    <div className="navbar-center">
                        <Navbar.Brand
                            onClick={() => routePath && changeRoute(routePath.route, { id: routePath.categoryId })}
                        >
                            <img
                                className="logo pointer"
                                src={logoLink.url}
                                alt="logo"
                                width={logoLink.width}
                                height={logoLink.height}
                            />
                        </Navbar.Brand>
                    </div>
                    <div className="navbar-right">
                        {/* <Navbar.Text>{user?.username}</Navbar.Text> */}
                        <NavDropdown title={user?.userinfo.given_name} id="nav-dropdown">
                            {/*<NavDropdown.Item eventKey="4.1">Preference</NavDropdown.Item>*/}
                            {/* <NavDropdown.Divider /> */}
                            <NavDropdown.Item onClick={editProfile}>{t('APP.PROFILE')}</NavDropdown.Item>
                            <div style={{ padding: '0px 14px' }}>
                                <LanguageSelector
                                    selectedLanguage={selectedLanguage}
                                    onSelectedLanguageChange={changeLanguage}
                                    availableLanguages={dropdownSelectableLangs}
                                />
                            </div>
                            <NavDropdown.Divider />
                            <NavDropdown.Item onClick={logout}>{t('APP.LOGOUT')}</NavDropdown.Item>
                        </NavDropdown>
                    </div>
                </Container>
            </Navbar>
        </header>
    );
};

export default HeaderLayout;
