import omit from 'lodash.omit';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { addErrorMessage } from '../../../app/errors/errorSlice';
import defaultDataToolbarBtn from '../../../components/shared/custom/sidebar/defaultDataToolbarBtn/DefaultDataToolbarBtn';
import ToolbarButton from '../../../components/shared/custom/toolbar/toolbarButton/ToolbarButton';
import useStateHistory from '../../../hooks/useStateHistory';
import { useRoute } from '../../../utils/routes/useRoute';
import useBackgroundForm from '../animations/adminSidebar/useBackgroundForm';
import { ICustomBackground } from '../animations/models/types';
import EditModeBtn from '../common/EditModeBtn';
import ContentPageLayoutV2 from '../ContentPageLayoutV2';
import RadarChart from './components/RadarChart';
import SidebarContent from './components/SidebarContent';
import { SkillsRadarConfig, SkillsRadarResult, SkillsScoreAttempt } from './models/type';
import {
    deleteSkillRadarConfig,
    getSkillRadarConfig,
    getSkillRadarResult,
    postSkillRadarConfig,
    putSkillRadarConfig,
} from './service/radar.service';

export function fixRadarDataFormReload(dataForm: any) {
    const skillRadarConfig: SkillsRadarConfig = dataForm;
    if (skillRadarConfig) {
        const skills: string[] | string = skillRadarConfig.skills;
        if (typeof skills === 'object' && skills.length > 0) {
            const skillsStringify = JSON.stringify(skills);
            skillRadarConfig.skills = skillsStringify;
            dataForm = skillRadarConfig;
        }
    }

    return dataForm;
}

export function fixRadarDataFormSubmit(data: SkillsRadarConfig) {
    if (data) {
        const skills: string = data.skills as string;
        const fixed: SkillsRadarConfig = {
            ...data,
            skills: JSON.parse(skills),
        };
        return fixed;
    } else {
        return data;
    }
}

function Radar() {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [isEditMode, setIsEditMode] = useState<boolean>(false);
    const [currentBg, setCurrentBg] = useState<ICustomBackground>();
    const { goBack } = useRoute();
    const { id } = useParams<{ id: string }>();
    const [skillRadarResult, setSkillRadarResult] = useState<SkillsRadarResult>();
    const [
        currentRadarConfig,
        setCurrentRadarConfig,
        { setIndex: setNewIndex, undo, redo, index, history, resetWith },
    ] = useStateHistory<SkillsRadarConfig>();
    const [isNewconfig, setIsnewConfig] = useState<boolean>(true);
    const [skills, setSkills] = useState<string[]>([]);

    const defaultSkillRadarConfig: SkillsRadarConfig = {
        categoryId: id,
        doubleAttempt: false,
        title: '',
        skills: [],
        rgbFirstAttempt: '#01a381', //colori di default
        rgbSecondAttempt: '#d66711',
    };

    const { btnListRight, btnListRightBg } = defaultDataToolbarBtn(index, history);
    const funcRadarToolbarBtn: { [key: string]: (data?: any) => void } = {
        save: () => {
            isNewconfig ? saveSkillRadarConfig() : updateSkillRadarConfig();
        },
        undo: () => {
            undo();
        },
        redo: () => {
            redo();
        },
        history: () => {
            setNewIndex(0);
        },
        delete: () => {
            deleteSkillRadarConfiguration();
            resetWith(defaultSkillRadarConfig);
        },
    };

    const { displayThumbs, selectedBg } = useBackgroundForm(
        id,
        {
            left: [],
            right: btnListRightBg,
        },
        'radars',
        'radar-bg'
    );

    useEffect(() => {
        getSkillRadarConfiguration(id);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        setCurrentBg(selectedBg);
    }, [selectedBg]);

    function getResult() {
        getSkillRadarResult(id)
            .then(res => setSkillRadarResult(res))
            .catch(err => console.log(err));
    }
    /*
    useEffect(() => {
        let parsedSkills: string[] = [];

        if (skillRadarResult && skills && skills.length > 0) {
            if (Array.isArray(skills)) {
                parsedSkills = skills;
            } else if (typeof skills === 'string') {
                try {
                    parsedSkills = JSON.parse(skills);
                } catch (error) {
                    console.error('Errore nel parsing di skills:', error);
                }
            }
            let orderedSkillsScoreFirstAttempt: SkillsScoreAttempt[] | undefined = undefined;

            orderedSkillsScoreFirstAttempt = parsedSkills.map(skill => {
                return skillRadarResult.skillsScoreFirstAttempt.find(
                    score => score.skill === skill
                ) as SkillsScoreAttempt;
            });

            let orderedSkillsScoreSecondAttempt: SkillsScoreAttempt[] | undefined = undefined;

            if (skillRadarResult.skillsScoreSecondAttempt && skillRadarResult.skillsScoreSecondAttempt.length > 0) {
                orderedSkillsScoreSecondAttempt = parsedSkills.map(skill => {
                    return skillRadarResult.skillsScoreSecondAttempt!.find(
                        score => score.skill === skill
                    ) as SkillsScoreAttempt;
                });
            }

            let orderedsSkillsScoreValuation: SkillsScoreAttempt[] | undefined = undefined;

            if (skillRadarResult.skillsScoreValuation && skillRadarResult.skillsScoreValuation.length > 0) {
                orderedsSkillsScoreValuation = parsedSkills.map(skill => {
                    return skillRadarResult.skillsScoreValuation!.find(
                        score => score.skill === skill
                    ) as SkillsScoreAttempt;
                });
            }
            skipEffectRef.current = true;
            if (
                JSON.stringify(skillRadarResult.skillsScoreFirstAttempt) !==
                    JSON.stringify(orderedSkillsScoreFirstAttempt) ||
                JSON.stringify(skillRadarResult.skillsScoreSecondAttempt) !==
                    JSON.stringify(orderedSkillsScoreSecondAttempt) ||
                JSON.stringify(skillRadarResult.skillsScoreValuation) !== JSON.stringify(orderedsSkillsScoreValuation)
            ) {
                skipEffectRef.current = true;
                setSkillRadarResult({
                    ...skillRadarResult,
                    skillsScoreFirstAttempt: orderedSkillsScoreFirstAttempt,
                    skillsScoreSecondAttempt: orderedSkillsScoreSecondAttempt,
                    skillsScoreValuation: orderedsSkillsScoreValuation,
                });
            }

            // Assicurati di gestire il caso in cui `find` non trova un elemento
            // orderedSkillsScoreFirstAttempt potrebbe contenere `undefined` se un elemento non viene trovato
            // Puoi filtrare gli `undefined` se necessario
            const filteredOrderedSkillsScoreFirstAttempt = orderedSkillsScoreFirstAttempt.filter(Boolean);
            debugger;
            // Usa `filteredOrderedSkillsScoreFirstAttempt` come necessario
            console.log(filteredOrderedSkillsScoreFirstAttempt);
        }
    }, [skills, skillRadarResult]);
*/
    function getSkillRadarConfiguration(categoryId: string) {
        getSkillRadarConfig(categoryId)
            .then(res => {
                setCurrentRadarConfig(res);
                setSkills(res.skills as string[]);
                setIsnewConfig(false);
                getResult();
            })
            .catch(err => {
                setCurrentRadarConfig(defaultSkillRadarConfig);
            });
    }

    function topToolbarRightTemplate() {
        return (
            <div>
                <EditModeBtn handlerAction={() => setIsEditMode(prev => !prev)} />
            </div>
        );
    }
    function bottomToolbarRightTemplate() {
        return (
            <div>
                <ToolbarButton clickAction={goBack} icon="exit_to_app" />
            </div>
        );
    }
    function handlerRadarActionToolbarBtn<T>(action: string, data?: T) {
        funcRadarToolbarBtn && funcRadarToolbarBtn[action] && funcRadarToolbarBtn[action](data);
    }

    function updateSkillRadarConfig() {
        currentRadarConfig &&
            putSkillRadarConfig(id, omit(fixRadarDataFormSubmit(currentRadarConfig), '_id'))
                .then(res => {
                    setCurrentRadarConfig(res);
                    getResult();
                })
                .catch(err =>
                    dispatch(
                        addErrorMessage({
                            title: t('ERRORSMSG.UPDATINGRADARFAILED'),
                        })
                    )
                );
    }
    function saveSkillRadarConfig() {
        currentRadarConfig &&
            postSkillRadarConfig(omit(fixRadarDataFormSubmit(currentRadarConfig), '_id'))
                .then(res => getSkillRadarConfiguration(res.categoryId))
                .catch(err =>
                    dispatch(
                        addErrorMessage({
                            title: t('ERRORSMSG.SAVINGRADARFAILED'),
                        })
                    )
                );
    }

    function deleteSkillRadarConfiguration() {
        currentRadarConfig &&
            deleteSkillRadarConfig(id).then(res => {
                setCurrentRadarConfig(defaultSkillRadarConfig);
                setSkillRadarResult(undefined);
                setIsnewConfig(true);
            });
    }
    function handleDataFormChange(data: SkillsRadarConfig) {
        setCurrentRadarConfig(fixRadarDataFormReload(data));
    }

    function sidebarTemplate() {
        return (
            <SidebarContent
                btnListLeft={[]}
                btnListRight={btnListRight}
                radarActionToolbarBtn={handlerRadarActionToolbarBtn}
                skillRadarConfig={fixRadarDataFormReload(currentRadarConfig)}
                tabBackground={displayThumbs}
                categoryId={id}
                handleDataFormChange={handleDataFormChange}
            />
        );
    }
    return (
        <>
            <ContentPageLayoutV2
                fullScreen
                toolbarRightTemplates={{
                    top: topToolbarRightTemplate(),
                    bottom: bottomToolbarRightTemplate(),
                }}
                contentLayout={
                    <div
                        className="d-flex align-items-center justify-content-center"
                        style={{
                            height: '100vh',
                            backgroundImage: `url(${currentBg ? currentBg.image.iconUrl : ''})`,
                            backgroundRepeat: 'no-repeat',
                            backgroundPosition: 'center',
                        }}
                    >
                        {skillRadarResult && (
                            <RadarChart
                                t={t}
                                skillRadarResult={skillRadarResult}
                                wCoefficient={0.4}
                                isLabeled={true}
                                skills={skills || []}
                            />
                        )}
                    </div>
                }
                sidebarContentTemplate={sidebarTemplate()}
                isEditMode={isEditMode}
            />
        </>
    );
}

export default Radar;
